import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Game2022",
    meta: {
      title: "游戏开发者峰会",
    },
    component: () =>
      import(/* webpackChunkName: "game2022" */ "../views/Game2022.vue"),
  },
  {
    path: "/campaign/tg2021",
    name: "ThinkGames2021",
    meta: {
      title: "手游出海破新界",
    },
    component: () => import(/* webpackChunkName: "ggds" */ "../views/GGDS.vue"),
  },
  {
    path: "/campaign/think_of_retail",
    name: "ThinkOfRetail",
    meta: {
      title: "谷歌跨境电商新篇章",
    },
    component: () =>
      import(
        /* webpackChunkName: "think_of_retail" */ "../views/ThinkOfRetail.vue"
      ),
  },
  {
    path: "/campaign/competitive_brand",
    name: "CompetitiveBrand",
    meta: {
      title: "成就全球强品牌",
    },
    component: () =>
      import(
        /* webpackChunkName: "competitive_brand" */ "../views/CompetitiveBrand.vue"
      ),
  },
  {
    path: "/assessment_tools/branding",
    name: "Branding",
    meta: {
      title: "谷歌跨境电商新篇章",
    },
    component: () =>
      import(
        /* webpackChunkName: "branding" */ "../views/assessment_tools/Branding.vue"
      ),
  },
  {
    path: "/campaign/ggds2021",
    name: "Game",
    meta: {
      title: "游戏开发者峰会 2021",
    },
    component: () => import(/* webpackChunkName: "game" */ "../views/Game.vue"),
  },
  {
    path: "/campaign/ggds2022",
    name: "Game2022",
    meta: {
      title: "游戏开发者峰会",
    },
    component: () =>
      import(/* webpackChunkName: "game" */ "../views/Game2022.vue"),
  },
  {
    path: "/campaign/gml",
    name: "GML",
    meta: {
      title: "最新产品与趋势",
    },
    component: () => import(/* webpackChunkName: "gml" */ "../views/GML.vue"),
  },
  {
    path: "/assessment_tools/branding/report",
    name: "Report",
    meta: {
      title: "谷歌跨境电商新篇章",
    },
    component: () =>
      import(
        /* webpackChunkName: "sites" */ "../views/assessment_tools/branding/Report.vue"
      ),
  },
  {
    path: "/assessment_tools/sites",
    name: "Sites",
    meta: {
      title: "谷歌跨境电商新篇章",
    },
    component: () =>
      import(
        /* webpackChunkName: "sites" */ "../views/assessment_tools/Sites.vue"
      ),
  },
  {
    path: "/videos/:id",
    name: "videos",
    meta: {
      title: "视频播放",
    },
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/Video.vue"),
  },
  {
    path: "/IOConnect",
    name: "IOConnect",
    meta: {
      title: "谷歌开发者大会",
      favicon: "/google-favicon.png",
    },
    component: () =>
      import(/* webpackChunkName: "ioconnect" */ "../views/IOConnect.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.favicon);
  console.log(to);
  next();
});

export default router;
