import "whatwg-fetch";
import { v4 as uuidv4 } from "uuid";

function initData() {
  if (window.localStorage.getItem("tracker") == null) {
    window.localStorage.setItem("tracker", uuidv4());
  }

  if (window.sessionStorage.getItem("session") == null) {
    window.sessionStorage.setItem("session", uuidv4());
  }
}

async function pushEvent(event, payload) {
  await fetch("https://google.36kr.com/api", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      session: window.sessionStorage.getItem("session"),
      tracker: window.localStorage.getItem("tracker"),
      event: event,
      payload: payload,
    }),
  });
}

export { initData, pushEvent };
