import Vue from "vue";
import VueMaterial from "vue-material";
import VueCarousel from "vue-carousel";

import Video from "video.js";
import "video.js/dist/video-js.css";

import "fullpage.js/vendors/scrolloverflow";
import VueFullPage from "vue-fullpage.js";
import AudioPlayer from "@liripeng/vue-audio-player";

import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "@liripeng/vue-audio-player/lib/vue-audio-player.css";

import App from "./App.vue";
import router from "./router";
import { initData, pushEvent } from "./utils/data";

Vue.config.productionTip = false;
Vue.use(VueMaterial);
Vue.use(VueCarousel);
Vue.use(VueFullPage);
Vue.use(AudioPlayer);
Vue.prototype.$pushEvent = pushEvent;
Vue.prototype.$video = Video;

initData();
pushEvent("touch", {});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
